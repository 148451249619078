import React, { Component, useEffect, useState } from "react";
import Clock from "../components/Clock";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { create as ipfsHttpClient } from 'ipfs-http-client'
import {useWeb3React} from '@web3-react/core';
import { navigate } from "@reach/router";
import NFTMarketplace from '../../NFTMarketplace.json';
import { marketplaceAddress } from "../../config";

const projectId = "2DWJHtZRz0Y2jpsPSKCxopx5f69"
const projectSecret = "fbe3801f275d21e445197918b7334672"
const projectIdAndSecret = `${projectId}:${projectSecret}`;
const ipfsURL = "https://braands.infura-ipfs.io/ipfs/";

const client = ipfsHttpClient({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
      'base64'
    )}`,
  },
})

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Createpage = () => {

  const [fileUrl,setFileUrl] = useState(null);
  const [formData,setFormData] = useState({title: '', description: '', price: ''});
  const {library,account} = useWeb3React();

  function onChange(e){
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  useEffect(()=>{
    if(!localStorage.getItem("WalletDetails")){
      window.open("/wallet", "_self")
    }
  },[]);

  async function onFileChange(e) {
    const file = e.target.files[0];
    try {
      const added = await client.add(
        file,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )
      const url = `${ipfsURL}${added.path}`
      setFileUrl(url);
    } catch (error) {
      console.log('Error uploading file: ', error)
    }  
  }
  async function uploadToIPFS() {
    const { title, description } = formData;

    /* first, upload to IPFS */
    const data = JSON.stringify({
      name: title, description, image: fileUrl
    })
    try {
      const added = await client.add(data)
      const url = `${ipfsURL}${added.path}`
      /* after file is uploaded to IPFS, return the URL to use it in the transaction */
      return url
    } catch (error) {
      console.log('Error uploading file: ', error)
    }  
  }

  async function listNFTForSale() {
    const url = await uploadToIPFS()
    // const web3Modal = new Web3Modal()
    // const connection = await web3Modal.connect()
    // const provider = new ethers.providers.Web3Provider(connection)
    // const signer = provider.getSigner()

    /* next, create the item */
    // const price = ethers.utils.parseUnits(formInput.price, 'ether')
    const price = library.utils.toWei(formData.price);
    let contract = new library.eth.Contract(NFTMarketplace.abi, marketplaceAddress)
    let listingPrice = await contract.methods.getListingPrice().call();
    listingPrice = listingPrice.toString()
    contract.methods.createToken(url, price).send({from: account, value: listingPrice }).then((transaction)=>{
      alert("Nft Created Successfully.");
      navigate("/explore")
    })
   
  }

    return (
      <div>
      <GlobalStyles/>

        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row m-10-hor'>
                <div className='col-12'>
                  <h1 className='text-center'>Create</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container'>

        <div className="row">
          <div className="col-lg-7 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">
                      <h5>Upload file</h5>

                      <div className="d-create-file">
                          <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                          {/* {this.state.files.map(x => 
                          <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb.{x.name}</p>
                          )} */}
                          <div className='browse'>
                            <input type="button" id="get_file" className="btn-main" value="Browse"/>
                            <input id='upload_file' type="file" onChange={onFileChange} />
                          </div>
                          
                      </div>

                      <div className="spacer-single"></div>

                      <h5>Title</h5>
                      <input type="text" name="title" id="item_title" value={formData.title} onChange={onChange} className="form-control" placeholder="e.g. 'Crypto Funk" />

                      <div className="spacer-10"></div>

                      <h5>Description</h5>
                      <textarea data-autoresize name="description" value={formData.description} onChange={onChange} id="item_desc" className="form-control" placeholder="e.g. 'This is very limited item'"></textarea>

                      <div className="spacer-10"></div>

                      <h5>Price</h5>
                      <input type="text" name="price" id="item_price" value={formData.price} onChange={onChange} className="form-control" placeholder="enter price for one item (ETH)" />

                      <div className="spacer-10"></div>

                      {/* <h5>Royalties</h5>
                      <input type="text" name="item_royalties" id="item_royalties" className="form-control" placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%" />

                      <div className="spacer-10"></div> */}

                      <input type="button" id="submit" onClick={listNFTForSale} className="btn-main" value="Create Item"/>
                  </div>
              </form>
          </div>

          <div className="col-lg-3 col-sm-6 col-xs-12">
                  <h5>Preview item</h5>
                  <div className="nft__item m-0">
                      {/* <div className="de_countdown">
                        <Clock deadline="December, 30, 2021" />
                      </div> */}
                      <div className="author_list_pp">
                          <span>                                    
                              <img className="lazy" src="./img/author/author-1.jpg" alt=""/>
                              <i className="fa fa-check"></i>
                          </span>
                      </div>
                      <div className="nft__item_wrap">
                          <span>
                              <img src={fileUrl ? fileUrl : "./img/collections/coll-item-3.jpg" } id="get_file_2" className="lazy nft__item_preview" alt=""/>
                          </span>
                      </div>
                      <div className="nft__item_info">
                          <span >
                              <h4>{formData.title ? formData.title : "Pinky Ocean"}</h4>
                          </span>
                          <div className="nft__item_price">
                              {formData.price ? formData.price +" BNB" : "0.08 BNB"}
                              {/* <span>1/20</span> */}
                          </div>
                          <div className="nft__item_action">
                              <span>Place a bid</span>
                          </div>
                          <div className="nft__item_like">
                              <i className="fa fa-heart"></i><span>0</span>
                          </div>                            
                      </div> 
                  </div>
              </div>                                         
      </div>

      </section>

        <Footer />
      </div>
   );
}

export default Createpage;