import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import ColumnNewRedux from "../components/ColumnNewRedux";
import * as selectors from '../../store/selectors';
import { fetchHotCollections } from "../../store/actions/thunks";
import api from "../../core/api";
import { useWeb3React } from '@web3-react/core';
import NFTMarketplace from '../../NFTMarketplace.json';
import { navigate } from "@reach/router";
import { marketplaceAddress } from "../../config";

// const GlobalStyles = createGlobalStyle`
//   header#myHeader.navbar.white {
//     background: #fff;
//   }
//   @media only screen and (max-width: 1199px) {
//     .navbar{
//       background: #403f83;
//     }
//     .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
//       background: #111;
//     }
//     .item-dropdown .dropdown a{
//       color: #111 !important;
//     }
//   }
// `;
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Colection = function ({ collectionId = 1 }) {
  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [currentNft, setCurrentNft] = React.useState(null);
  const [resalePrice, setResalePrice] = React.useState("");
  const { library, active, account } = useWeb3React();


  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
  };

  const dispatch = useDispatch();
  const hotCollectionsState = useSelector(selectors.hotCollectionsState);
  const hotCollections = hotCollectionsState.data ? hotCollectionsState.data[0] : {};

  useEffect(() => {
    dispatch(fetchHotCollections(collectionId));
  }, [dispatch, collectionId]);

  async function listNFTForSale() {

    const priceFormatted = library.utils.toWei(resalePrice)
    let contract = new library.eth.Contract(NFTMarketplace.abi, marketplaceAddress)
    let listingPrice = await contract.methods.getListingPrice().call();

    listingPrice = listingPrice.toString()
    contract.methods.resellToken(currentNft.tokenId, priceFormatted).send({from: account ,value: listingPrice }).then(transaction=>{
      alert("Resale Nft Successfully.");
      navigate("/colection/"+account)
    })
   
  }

  return (
    <div>
      <GlobalStyles />
      {hotCollections.author && hotCollections.author.banner &&
        <section id='profile_banner' className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${api.baseUrl + hotCollections.author.banner.url})` }}>
          <div className='mainbreadcumb'>
          </div>
        </section>
      }

      <section className='container d_coll no-top no-bottom'>
        <div className='row'>
          <div className="col-md-12">
            <div className="d_profile">
              <div className="profile_avatar">
                {hotCollections.author && hotCollections.author.avatar &&
                  <div className="d_profile_img">
                    <img src={api.baseUrl + hotCollections.author.avatar.url} alt="" />
                    <i className="fa fa-check"></i>
                  </div>
                }
                <div className="profile_name">
                  <h4>
                    {hotCollections.name}
                    <div className="clearfix"></div>
                    {hotCollections.author && hotCollections.author.wallet &&
                      <span id="wallet" className="profile_wallet">{collectionId ? collectionId : 1}</span>
                    }
                    <button id="btn_copy" title="Copy Text">Copy</button>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className="items_filter">
              <ul className="de_nav">
                <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>My Listed Items</span></li>
                <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Owned</span></li>
              </ul>
            </div>
          </div>
        </div>
        {openMenu && (  
          <div id='zero1' className='onStep fadeIn'>
            <ColumnNewRedux shuffle showLoadMore={false} authorId={collectionId ? collectionId : 1} listed={true} />
          </div>
        )}
        {openMenu1 && (
          <div id='zero2' className='onStep fadeIn'>
            <ColumnNewRedux shuffle setOpenCheckout={setOpenCheckout} setCurrentNft={setCurrentNft} showLoadMore={false} authorId={collectionId ? collectionId : 1} />
          </div>
        )}
      </section>
      {openCheckout &&
        <div className='checkout'>
          <div className='maincheckout'>
            <button className='btn-close' onClick={() => setOpenCheckout(false)}>x</button>
            <div className='heading'>
              <h3>Resale</h3>
            </div>
            <p>You are about to resale a <span className="bold">{currentNft.name ? currentNft.name : 'Item'}</span>
              <span className="bold"> from Monica Lucas</span></p>
            <div className='detailcheckout mt-4'>
              <div className='listcheckout'>
                <h6>
                  Enter Resale Price.
                  {/* <span className="color">10 available</span> */}
                </h6>
                <input type="text" name="resale_price" id="resale_price" value={resalePrice} required onChange={(e)=>{ setResalePrice(e.target.value) }} className="form-control" />
              </div>
            </div>
            {/* <div className='heading mt-3'>
              <p>Your balance</p>
              <div className='subtotal'>
                10.67856 ETH
              </div>
            </div>
            <div className='heading'>
              <p>Service fee 2.5%</p>
              <div className='subtotal'>
                0.00325 ETH
              </div>
            </div>
            <div className='heading'>
              <p>You will pay</p>
              <div className='subtotal'>
                0.013325 ETH
              </div>
            </div> */}
            <button className='btn-main lead mb-5' onClick={listNFTForSale}>Checkout</button>
          </div>
        </div>
      }
      <Footer />
    </div>
  );
}
export default memo(Colection);