import React, { memo } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import api from '../../core/api';
import {useWeb3React} from '@web3-react/core';
import NFTMarketplace from '../../NFTMarketplace.json';
import { marketplaceAddress } from '../../config';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4',setCurrentNft, setOpenCheckout ,clockTop = true, height, onImgLoad }) => {

    const {library,account} = useWeb3React();

    const navigateTo = (link) => {
        navigate(link);
    }

    function buyNft(nft) {
        /* needs the user to sign the transaction, so will use Web3Provider and sign it */
        // const web3Modal = new Web3Modal()
        // const connection = await web3Modal.connect()
        // const provider = new ethers.providers.Web3Provider(connection)
        // const signer = provider.getSigner()
        // const contract = new ethers.Contract(marketplaceAddress, NFTMarketplace.abi, signer)
        let contract = new library.eth.Contract(NFTMarketplace.abi, marketplaceAddress)
    
        // /* user will be prompted to pay the asking proces to complete the transaction */
        const price = library.utils.toWei(nft.price);
        contract.methods.createMarketSale(nft.tokenId).send({from: account, value: price }).then((transaction)=>{
            alert("Buy Nft Successfully.");
            navigate("/explore")
        })
      }

    return (
        <div className={className}>
            <div className="nft__item m-0">
                <div className="author_list_pp">
                    <span>                                    
                        <img className="lazy" src="/img/author_single/author_thumbnail.jpg" alt=""/>
                        <i className="fa fa-check"></i>
                    </span>
                </div>
                <div className="nft__item_wrap" style={{height: `${height}px`}}>
                <Outer>
                    <span>
                        <img onLoad={onImgLoad} src={nft.image? nft.image : "https://images.pexels.com/photos/4061977/pexels-photo-4061977.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} className="lazy nft__item_preview" alt=""/>
                    </span>
                </Outer>
                </div>
                <div className="nft__item_info">
                    <span>
                        <h4>{nft.name ? nft.name : "No Title"}</h4>
                    </span>
                    {/* <span>
                        <p className='mb-0'>{nft.description.substr(0, 35)}{nft.description.length>36 && "..."}</p>
                    </span> */}
                    { nft?.status === 'has_offers' ? (
                            <div className="has_offers">
                                <span className='through'>{nft.priceover}</span> {nft.price} BNB
                            </div> 
                        ) : (
                            <div className="nft__item_price">
                                {nft.price} BNB
                                { nft?.status === 'on_auction' && 
                                    <span>{nft?.bid}/{nft?.max_bid}</span>
                                }
                            </div>
                        )
                    }
                    <div className="nft__item_action">
                        { nft?.status === 'list' ? <span onClick={()=>{setOpenCheckout(true); setCurrentNft(nft); }}>List To Marketplace</span> : <span onClick={()=>{ buyNft(nft) }}>Buy Now</span> }
                    </div>
                    <div className="nft__item_like">
                        <i className="fa fa-heart"></i><span> {nft?.likes}</span>
                    </div>                            
                </div> 
            </div>
        </div>             
    );
};

export default memo(NftCard);