import axios from "axios";
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions/thunks';
import { clearNfts, clearFilter } from '../../store/actions';
import NftCard from './NftCard';
import NftMusicCard from './NftMusicCard';
import { shuffleArray } from '../../store/utils';
import { useWeb3React } from '@web3-react/core';
import NFTMarketplace from '../../NFTMarketplace.json';
import Web3 from "web3";
import {marketplaceAddress} from "../../config";

//react functional component
const ColumnNewRedux = ({ showLoadMore = true, shuffle = false, authorId = null, setOpenCheckout = null, listed = null, setCurrentNft = null, type = null }) => {

    const { library, active, account } = useWeb3React();

    const dispatch = useDispatch();
    // const nftItems = useSelector(selectors.nftItems);
    const [nfts, setNfts] = useState([])
    const [height, setHeight] = useState(0);

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }

    // useEffect(() => {
    //     dispatch(actions.fetchNftsBreakdown(authorId));
    // }, [dispatch, authorId]);

    // //will run when component unmounted
    // useEffect(() => {
    //     return () => {
    //         dispatch(clearFilter());
    //         dispatch(clearNfts());
    //     }
    // }, [dispatch]);

    const loadMore = () => {
        dispatch(actions.fetchNftsBreakdown(authorId));
    }

    async function loadNFTs() {
        /* create a generic provider and query for unsold market items */
        let contract;
        let data, items;
        if (authorId && library) {
            library.eth.defaultAccount = authorId;
            console.log(library.eth.defaultAccount,"123");
            contract = new library.eth.Contract(NFTMarketplace.abi, marketplaceAddress);
            if (listed) {
                data = await contract.methods.fetchItemsListed().call();
                console.log(data);
            } else {
                data = await contract.methods.fetchMyNFTs().call();
            }
            items = await Promise.all(data.map(async i => {
                const tokenUri = await contract.methods.tokenURI(i.tokenId).call();
                const meta = await axios.get(tokenUri)
                let price = library.utils.fromWei(i.price.toString())
                let item = {
                    price,
                    tokenId: i.tokenId,
                    seller: i.seller,
                    owner: i.owner,
                    status: "list",
                    image: meta.data.image,
                    name: meta.data.name,
                    description: meta.data.description,
                }
                return item
            }))
        } else if(!authorId) {
            const web3 = new Web3(Web3.givenProvider || "https://data-seed-prebsc-1-s1.binance.org:8545");
            contract = new web3.eth.Contract(NFTMarketplace.abi, marketplaceAddress);
            data = await contract.methods.fetchMarketItems().call();
            items = await Promise.all(data.map(async i => {
                const tokenUri = await contract.methods.tokenURI(i.tokenId).call();
                const meta = await axios.get(tokenUri)
                let price = web3.utils.fromWei(i.price.toString())
                let item = {
                    price,
                    tokenId: i.tokenId,
                    seller: i.seller,
                    owner: i.owner,
                    image: meta.data.image,
                    name: meta.data.name,
                    description: meta.data.description,
                }
                return item
            }));
        }
        setNfts(items)
    }

    useEffect(() => {
        loadNFTs();
    }, [active]);

    return (
        <div className='row'>
            {type === "popular" ? 
            nfts && nfts.slice(0,4).map((nft, index) => {
                return (
                    nft.category === 'music' ?
                        <NftMusicCard nft={nft} audioUrl={nft.audio_url} key={index} onImgLoad={onImgLoad} height={height} />
                        :
                        <NftCard nft={nft} key={index} setCurrentNft={setCurrentNft} setOpenCheckout={setOpenCheckout} onImgLoad={onImgLoad} height={height} />
                )
            })
            :
            nfts && nfts.map((nft, index) => {
                return (
                    nft.category === 'music' ?
                        <NftMusicCard nft={nft} audioUrl={nft.audio_url} key={index} onImgLoad={onImgLoad} height={height} />
                        :
                        <NftCard nft={nft} key={index} setCurrentNft={setCurrentNft} setOpenCheckout={setOpenCheckout} onImgLoad={onImgLoad} height={height} />
                )
            })}
        </div>
    );
};

export default memo(ColumnNewRedux);